.page {
  text-align: center;
  color: hsla(0, 100%, 3%, 1);
}

.page-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-static {
  margin-top: 60px;
  padding-bottom: 60px;
}

.page-heading-h1 {
  font-family: "Quicksand", Sans-serif;
  margin-top: 0;
  font-size: 60px;
  text-transform: uppercase;
  line-height: 71px;
}

.page-heading-h2 {
  font-size: 32px;
}

.page-heading-h3,
.page-text {
  font-size: 21px;
}

.page-heading-h4 {
  font-size: 18px;
}

.button-main {
  font-family: "Work Sans", Sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
  background-color: #6172d2;
  border-radius: 2px 2px 2px 2px;
  padding: 14px 30px 13px 30px;
  text-decoration: none;
  text-align: center;
}

.button-light {
  font-family: "Work Sans", Sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 2px;
  padding: 14px 30px 13px 30px;
  border: 2px solid white;
  text-decoration: none;
}

.button-main:hover,
.button-main:focus {
  color: #e49c9c;
  background-color: #ffffff;
}

.subtitle {
  color: #191919;
}

.text-center {
  text-align: center;
}
