.menu-desktop {
  display: none;

  @media (min-width: 992px) {
    display: initial;
    background-color: #fdfdfd;
    padding: 15px 75px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  }

  .link-logo {
    img {
      width: 45%;
    }
  }

  .navigation {
    ul {
      display: block;

      li {
        display: inline-block;
        padding: 0 25px;

        a {
          color: #273040;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 500;
          transition: color 0.25s ease-in-out;

          &:hover {
            color: #fa8231;
          }
        }
      }
    }
  }

  .languages {
    ul {
      display: block;
      text-align: right;
      padding-left: 0;

      li {
        display: inline-block;

        &:first-child {
          padding-right: 15px;
        }

        button {
          border: none;
          background-color: transparent;
          cursor: pointer;

          span {
            transition: transform 0.25s ease-in-out;

            &.activ {
              transform: scale(1.5);
            }

            &:hover {
              transform: scale(1.5);
            }
          }
        }
      }
    }
  }
}
