.pricebox {
  box-shadow: 0px 0px 40px 11px rgba(79, 118, 157, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 40px 0px;
  padding: 40px 0px 40px 0px;
  background-color: white;
  border-radius: 10px;

  @media (min-width: 768px) {
    margin: 40px 59px 0px 59px;
  }
}

.pricebox-content {
  padding: 0 20px;
}

.price {
  display: block;
  font-size: 35px;
  line-height: 70px;
  font-weight: 800;
  letter-spacing: 1px;
  margin-bottom: 20px;
  color: #e49c9c;
}

.title {
  color: #191919;
  font-family: "Quicksand", Sans-serif;
  font-size: 22px;
  font-weight: 600;
  height: 70px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.description {
  border-top: 2px solid #191919;
  border-bottom: 2px solid #191919;
  padding: 30px 0;
}

.description-item {
  display: inline-block;
  margin-bottom: 20px;
}
