footer {
  padding: 80px 0;
  font-size: 16px;
  color: #707070;

  h4 {
    font-family: Quicksand, Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #191919;
    margin-bottom: 50px;
  }

  .footer-logo {
    width: 60%;
    margin-bottom: 50px;
  }
}
