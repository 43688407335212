@-ms-viewport {
  width: device-width;
}

html,
body,
.root {
  width: 100%;
  height: 100%;
}

html {
  box-sizing: border-box;
  font-size: 100%;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  position: relative;
  padding: 0;
  margin: 0;
  font: 1rem / 1.15 "Work Sans", sans-serif;
  background-color: hsla(0, 100%, 100%, 1);
  -webkit-text-size-adjust: 100%; /* Prevent adjustments of font size after orientation changes in iOS. */
}

[hidden] {
  display: none;
}

.root {
  position: relative;
}

a {
  cursor: pointer;
  transition: all 0.5s;
}

img {
  max-width: 100%;
}

h3 {
  margin-bottom: 0.5rem;
  font-weight: 700;
}
