.icon-box {
  padding: 46px 25px 31px 25px;
  border-radius: 2px 2px 2px 2px;
  text-align: center;
}

.icon-box .icon svg {
  width: 3rem;
  height: 3rem;
  fill: #e49c9c;
  color: #e49c9c;
}

.content-title {
  color: #191919;
  font-family: "Quicksand", Sans-serif;
  font-size: 22px;
  line-height: 29px;
}

.content-text {
  color: #595959;
  font-family: "Work Sans", Sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.icon-box:hover {
  background-color: #e49c9c;
}

.icon-box:hover svg,
.icon-box:hover h3,
.icon-box:hover p {
  color: white;
  fill: white;
}

.icon-box.white-box {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 40px 11px rgba(79, 118, 157, 0.1);
  margin: 0 20px;
}

.icon-box.white-box:hover svg {
  color: #e49c9c;
}

.icon-box.white-box:hover h3 {
  color: #191919;
}

.icon-box.white-box:hover p {
  color: #595959;
}
