%section-h2 {
  font-family: "Quicksand", Sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 48px;
}

%section-p {
  font-family: "Work Sans", Sans-serif;
  font-size: 18px;
  font-weight: 400;
}

%section-content-p {
  margin: 16px 0px 47px 0px;
  line-height: 34px;
}

.page-home {
  margin-top: 66px;
}

.section-welcome {
  background-image: url(../../../images/Welcome.jpg);
  background-color: #0a8ae6;
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(100vh - 66px);
  position: relative;
}

.section-welcome .button-main {
  position: absolute;
  bottom: 12%;
}

.section-content {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;

  span {
    text-align: center;
  }
}

.section-welcome .section-content {
  background: rgba(196, 215, 228, 0.5);
}

.subtitle {
  font-family: "Work Sans", Sans-serif;
  font-size: 18px;
  font-weight: 300;
}

.section-approach .section-content {
  padding: 10px;
  color: #595959;
}

.section-approach .section-content h2 {
  @extend %section-h2;
  margin-top: 70px;
}

.icon-box-wrapper {
  display: flex;
}

.section-quote {
  background-color: #e49c9c;
  padding: 80px 0;
  font-family: "Quicksand", Sans-serif;
  font-size: 30px;
  font-weight: 600;
}

.section-quote blockquote {
  font-style: italic;
}

.author {
  text-transform: uppercase;
  font-size: 20px;
  text-align: right;
  width: 100%;
  padding-right: 10%;
}

.author-wrapper {
  position: relative;
  width: 207px;
  margin-top: 41px;
}

.author-wrapper svg {
  font-size: 70px;
  opacity: 0.3;
  position: absolute;
  top: -39px;
  right: -42px;
}

.section-presentation {
  margin: 90px auto;

  h2 {
    @extend %section-h2;
    margin-top: 0;
  }

  p {
    @extend %section-p;
    color: #707070;
    margin-bottom: 50px;
  }

  a.no-hover:hover {
    color: #fff;
    background-color: #0a8ae6;
  }
}

.section-myapproach-therapies {
  position: relative;

  .section-myapproach {
    font-family: "Work Sans", Sans-serif;
    background-image: url(https://lh3.googleusercontent.com/-oOdqplfEqJD4e613RqAO414jsBxPpB6XVwal6txwz3fNItLg5tpsenhREkS_zi7aHoJiQJG=w960-h960-n-o-v1);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    height: 545px;

    h2.page-heading-h2 {
      font-size: 42px;
      font-weight: 700;
      line-height: 48px;
    }

    .blue-bg {
      background: rgba(10, 138, 230, 0.5);
      height: 100%;
      text-align: center;
      color: white;
    }

    .minititle {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding-top: 150px;
    }
  }

  .section-therapies {
    @media (min-width: 992px) {
      width: 100%;
      position: absolute;
      top: 345px;
    }

    .row {
      & > div {
        margin-top: 15px;

        &:last-child {
          margin-bottom: 15px;
        }

        @media (min-width: 992px) {
          margin-top: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.section-discussion {
  background: #f7f7f7;

  @media (min-width: 768px) {
    padding-top: 150px;
  }

  @media (min-width: 992px) {
    padding-top: 100px;
  }

  h2 {
    margin-top: 70px;
  }

  p {
    @extend %section-content-p;
  }
}

.section-price {
  font-family: "Work Sans", Sans-serif;
  margin: 90px auto;
}

.section-cns {
  background: #f7f7f7;
  color: #595959;
  padding: 90px 0;

  .container {
    @media (min-width: 992px) {
      padding: 240px 0 0 0;
    }
  }
  p {
    @extend %section-content-p;
  }
}

.section-legal {
  background: #e49c9b;
  font-family: "Quicksand", Sans-serif;
  font-size: 30px;
  font-weight: 600;

  p {
    font-size: 18px;
    margin: 16px 0px 47px 0px;
    line-height: 34px;
  }

  .section-content {
    padding: 90px 0;
  }
}

.section-approach {
  margin-bottom: 51px;
}
