.link {
  text-decoration: none;
  color: hsla(0, 100%, 3%, 1);
  cursor: pointer;
  transition: color 0.25s ease-in-out;
}

.link:hover,
.link.activ {
  color: hsla(24.2, 95.3%, 58.6%, 1);
}
